import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SidebarParent from "./Components/SidebarParent";
import Dashboard from "./Components/Dashboard.jsx";
import Settings from "./Pages/Settings.jsx";
import Bookings from "./Pages/Bookings.jsx";
import ScrollToTop from "./Components/Scroll.jsx";
import SignIn from "./Pages/Default/SignIn.jsx";
import ChangePass from "./Pages/FirstLogin/ChangePass.jsx";
import Landing from "./Pages/Landing.jsx";
import FirstLogin from "./Pages/FirstLogin/FirstLogin.jsx";
import { useAuth } from "./Components/AuthContext";
import ForgotPassword from "./Pages/Default/ForgotPassword.jsx";
import ResetPass from "./Pages/Default/ResetPassword.jsx";
import Email from "./Pages/Default/Email.jsx";
import Success from "./Pages/Default/Success.jsx";
import SlideOver from "./Components/SlideOver.jsx";
import PopupMessage from "./Components/ScreenSize.jsx";
import NotFound from "./Pages/NotFound.jsx";
import LoginError from "./Pages/LoginError.jsx";
import { EstateCodeProvider } from "./Components/EstateCodeContext.jsx";

function App() {
  const { authenticated, login } = useAuth();
  const [gifPlayed, setGifPlayed] = useState(authenticated);
  const [open, setOpen] = useState(window.innerWidth < 1024);

  // useEffect(() => {
  //   const gifDuration = 3500;

  //   const timeout = setTimeout(() => {
  //     setGifPlayed(true);
  //   }, gifDuration);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("authenticated") === "true";
    if (isAuthenticated) {
    }
  }, []);

  useEffect(() => {
    const gifDuration = 3500;

    const timeout = setTimeout(() => {
      setGifPlayed(true);
    }, gifDuration);

    const handleResize = () => {
      setOpen(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <EstateCodeProvider>
          {open && <PopupMessage />}
          <div className="App">
            {gifPlayed ? (
              authenticated ? (
                <>
                  <div className="sidebar-wrapper">
                    <SidebarParent />
                  </div>
                  <div className="inside-app">
                    <Routes>
                      <Route path="/" element={<Navigate to="/Dashboard" />} />
                      <Route path="/Dashboard" element={<Dashboard />} />
                      <Route path="/Settings" element={<Settings />} />
                      <Route path="/Bookings" element={<Bookings />} />
                      <Route path="/slide" element={<SlideOver />} />
                      <Route path="/*" element={<NotFound />} />
                      <Route path="/errorlogin" element={<LoginError />} />
                    </Routes>
                  </div>
                </>
              ) : (
                <div className="w-screen">
                  <Routes>
                    <Route path="/" element={<SignIn login={login} />} />
                    <Route path="/welcome" element={<FirstLogin />} />
                    <Route path="/changepassword" element={<ChangePass />} />
                    <Route
                      path="/forgotpassword"
                      element={<ForgotPassword />}
                    />
                    <Route path="/email" element={<Email />} />
                    <Route path="/success" element={<Success />} />
                    <Route
                      path="/resetpassword/:token"
                      element={<ResetPass />}
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/errorlogin" element={<LoginError />} />
                    <Route path="/*" element={<NotFound />} />
                  </Routes>
                </div>
              )
            ) : (
              <div className="w-screen">
                <Landing />
              </div>
            )}
          </div>
        </EstateCodeProvider>
      </ScrollToTop>
    </Router>
  );
}

export default App;
